* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  font-family: Helvetica, Arial, sans-serif;
  color: #333e5a;
  min-height: 100%;
}

header {
  background: #f0293e;
  color: #fff;
  text-align: center;
  flex-grow: 0;
  margin-bottom: 2em;
}

h1 {
  font-weight: 300;
  padding: 0.4em 0;
}

#root {
  min-height: 100vh;
}

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  background: #ffffff;
  flex-grow: 1;
}

form {
  max-width: 300px;
  margin: 0 auto;
}

h2 {
  font-weight: 300;
  margin-bottom: 1em;
  text-align: center;
}

form > div {
  width: 100%;
  margin-bottom: 1em;
}
form > div > label {
  display: block;
  margin-bottom: 0.3em;
}
form > div > input {
  display: block;
  width: 100%;
  font-size: 16px;
  padding: 0.4em;
  border-radius: 6px;
  border: 1px solid #333e5a;
}

button {
  background: #333e5a;
  color: #fff;
  font-size: 14px;
  padding: 0.4em;
  border-radius: 3px;
  border: 1px solid transparent;
}
button:hover {
  filter: brightness(150%);
}

.room {
  /* margin-top: 34px; */
  position: absolute;
  height: 100%;
  width: 100%;
  display: grid;
}
.room h4 {
  font-size: 12px;
}
.room button {
  position: absolute;
  top: 0;
  right: 20px;
}
.room > h3 {
  text-align: center;
  font-weight: 300;
  margin-bottom: 1em;
}

.local-participant {
  text-align: center;
  margin-bottom: 2em;
  position: absolute;
}
.remote-participants {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  /* padding: 0 2em 2em; */
}
.remote-participants > .participant {
  width: 100%;
}
.remote-participants > .participant > video {
  height: 100%;
  object-fit: cover;
}
.participant {
  /* background: #333e5a; */
  /* padding: 10px; */
  /* border-radius: 6px; */
  display: inline-block;
  margin-right: 10px;
}
.participant:last-child {
  margin-right: 0;
}
.participant h3 {
  font-size: 12px;
  text-align: center;
  padding-bottom: 0.5em;
  /* color: #fff; */
}

video {
  width: 100%;
  /* max-width: 600px; */
  display: block;
  margin: 0 auto;
  border-radius: 2px;
}

footer {
  background: #333e5a;
  color: #fff;
  text-align: center;
  flex-grow: 0;
  padding: 1em 0;
}

footer a {
  color: #fff;
}

/* custom */
.hostuser {
  /* position: "absolute"; */
  border: 2px #ffffff solid;
  border-radius: 3px;
  width: 100px;
  height: auto;
  font-size: 12px;
  margin: 7px 0px 0px 5px;
}

.hostuser > .participant > h3 {
  position: absolute;
  top: 58px;
  color: #ffffff;
  font-size: 11px;
  font-weight: lighter;
  margin-left: 3px;
}
.remote-participants > .participant > h3 {
  position: absolute;
  /* top: 38px; */
  color: #ffffff;
  font-size: 11px;
  font-weight: lighter;
  /* right: 21px; */
  bottom: 0;
  margin-left: 5px;
}
.vd_text {
  width: 63%;
  font-size: 12px;
  font-weight: normal;
}

.logout {
  background-color: #f8031c;
  /* float: right; */
  width: 35%;
  margin-right: 7px;
  font-size: 12px !important;
}
